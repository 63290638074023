import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TermsAndConditions from "./TermsContent";
import FrontPageFooter from "../components/FrontPageFooter";
import Header from "../components/Header";

const TermsConditionPage = () => {
  return (
    <div>
      <TermsAndConditions />
      <FrontPageFooter />
    </div>
  );
};

export default TermsConditionPage;
