import axios from "axios";
import CONFIG from "../config";
import { v4 as uuidv4 } from "uuid";
import AES from "crypto-js/aes";
import { useState, useEffect } from "react";
import Papa from "papaparse";
import Logger from "../utils/logger"

//function to check if user is a repeat user

export const checkIfRepeatUser = async () => {
  const userEmail = localStorage.getItem("userEmail") || "";
  const postData = new FormData();
  postData.append("user_email", JSON.stringify(userEmail));

  try {
    const response = await axios({
      method: "post",
      url: `${CONFIG.BASE_URL}/repeat_user`,
      data: postData,
      headers: { "Content-Type": "multipart/form-data" },
    });

    const data = response.data;
    return data.isRepeatUser;
  } catch (error) {

    Logger.error("Error checking repeat user on primary server:");

    // Trying backup URL
    try {
      const backupResponse = await axios({
        method: "post",
        url: `${CONFIG.BACKUP_URL}/repeat_user`,
        data: postData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      const backupData = backupResponse.data;
      return backupData.isRepeatUser;
    } catch (backupError) {
      Logger.error(
        "Error checking repeat user on backup server:",
        backupError
      );
      return false;
    }
  }
};

// Function to directly send message to the server
export async function sendBotMessage(data) {
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  const accessToken = localStorage.getItem("access_token");

  // Add Authorization only if accessToken exists
  if (accessToken) {
    headers["Authorization"] = `Bearer ${accessToken}`;
  }

  try {
    // First try with the primary URL
    const response = await axios.post(`${CONFIG.BASE_URL}/reply`, data, {
      headers,
    });
    Logger.debug("this is response data 22222", response.data);
    return response.data;
  } catch (error) {
    Logger.debug("Primary server failed, trying backup server.");
    try {
      // If primary fails, try with the backup URL
      const backupResponse = await axios.post(
        `${CONFIG.BACKUP_URL}/reply`,
        data,
        { headers }
      );
      return backupResponse.data;
    } catch (backupError) {
      // Handle case where backup also fails
      Logger.error("Backup server also failed:", backupError);
      throw backupError; // Or handle it as per your logic
    }
  }
}

// Function to prepare data and call sendBotMessage, encrypt the messages field
export async function prepareAndSendBotMessage(
  messages,
  humanMessage,
  messageIndex,
  humanTimestamp
) {
  const userEmail = localStorage.getItem("userEmail") || "";
  const sessionId = sessionStorage.getItem("uuid");

  const aiReplies = messages
    .filter((msg) => msg.type === "bot")
    .map((msg) => msg.text);
  let humanReplies = messages
    .filter((msg) => msg.type === "user")
    .map((msg) => msg.text);
  humanReplies.push(humanMessage); // Append the latest user message

  //encrypt human turns
  const encryptedHumanTurns = AES.encrypt(
    JSON.stringify(humanReplies),
    process.env.REACT_APP_SECRET_KEY
  ).toString();

  //encrypt ai turns
  const encryptedAITurns = AES.encrypt(
    JSON.stringify(aiReplies),
    process.env.REACT_APP_SECRET_KEY
  ).toString();

  const postData = new FormData();
  Logger.debug("this is human time stamp", humanTimestamp);
  //encrypt human turns
  postData.append("human_turns", encryptedHumanTurns);
  postData.append("ai_turns", encryptedAITurns);
  postData.append("human_timestamp", JSON.stringify(humanTimestamp));
  postData.append("api_key_value", JSON.stringify("aurora"));
  postData.append("session_id_value", JSON.stringify(sessionId));
  postData.append("user_email", JSON.stringify(userEmail));
  postData.append("user_correction", JSON.stringify(null));
  postData.append("message_id_db", JSON.stringify(messageIndex));
  // postData.append("bot_message_id", JSON.stringify(messageIndex));

  return sendBotMessage(postData);
}

export async function sendRating(
  ratedMessage,
  ratingType,
  messageId,
  sessionId
) {
  try {
    const postData = new FormData();

    // Add condition for up or down rating directly here
    postData.append("user_rating_down", JSON.stringify(ratingType === "down"));
    postData.append("user_rating_up", JSON.stringify(ratingType === "up"));
    postData.append("rating_id", JSON.stringify(messageId));
    postData.append("session_id_value", JSON.stringify(sessionId));

    const response = await axios.post(`${CONFIG.BASE_URL}/rating`, postData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    // If necessary, update local state or perform actions based on server's response
    Logger.debug("Rating submitted successfully", response.data);
  } catch (error) {
    Logger.error("Error sending rating:", error);
  }
}

export const requestSummarySurrogate = async () => {
  const userEmail = localStorage.getItem("userEmail") || "";
  const sessionId = sessionStorage.getItem("uuid");

  const postData = new FormData();
  postData.append("user_email", JSON.stringify(userEmail));
  postData.append("session_id_value", JSON.stringify(sessionId));

  try {
    const response = await axios({
      method: "post",
      url: `${CONFIG.BASE_URL}/update_memory`,
      data: postData,
      headers: { "Content-Type": "multipart/form-data" },
    });

    Logger.debug("Summary request response:", response.data);
    return response.data;
  } catch (error) {
    Logger.error("Primary server error. Trying backup server:", error);
    try {
      const responseBackup = await axios({
        method: "post",
        url: `${CONFIG.BACKUP_URL}/update_memory`,
        data: postData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      Logger.debug(
        "Backup server summary request response:",
        responseBackup.data
      );
      return responseBackup.data;
    } catch (backupError) {
      Logger.error(
        "Error requesting summary from backup server:",
        backupError
      );
      return null;
    }
  }
};

//send rewrite message to server
export const sendRewrittenMessage = async (
  messageId,
  originalText,
  rewrittenText
) => {
  try {
    const postData = new FormData();
    postData.append("message_id", messageId);
    postData.append("original_text", originalText);
    postData.append("rewritten_text", rewrittenText);

    await axios.post(`${CONFIG.BASE_URL}/rewrite`, postData);
  } catch (error) {
    Logger.error("Error sending rewritten message:", error);
  }
};

//save rewritten conversation
export const saveAndUploadConversation = async (
  messages,
  sessionId,
  addMessage
) => {
  // Include headers for original texts as well
  let csvContent =
    "Mia_text,User_text,Mia_original_text,User_original_text,Mia_rewritten_text,User_rewritten_text,stateInfo,nodeType,session_id\r\n";

  // Determine if the user is a repeat user, the first stateInfo and nodeType should be different for repeat and new user
  const isRepeatUser = sessionStorage.getItem("isRepeatUser") === "true";
  let isFirstBotMessage = true; // Correctly initializing and naming the flag
  for (let i = 0; i < messages.length; i++) {
    let message = messages[i];

    if (message.type === "bot") {
      let miaOriginalText = message.originalText?.replace(/"/g, '""') || "";
      let miaText = message.text?.replace(/"/g, '""') || "";
      let miaRewrittenText = miaOriginalText ? miaText : '""';

      let nextMessage = messages[i + 1];
      let userText = "",
        userOriginalText = "",
        userRewrittenText = '""';

      let miaStateInfo, nodeType;

      if (isFirstBotMessage) {
        // Apply special case for the first bot message
        miaStateInfo = isRepeatUser ? `ic1` : `i0`;
        nodeType = `universal`;
        isFirstBotMessage = false; // Update the flag here
      } else {
        // For all subsequent bot messages, use their own stateInfo and nodeType
        miaStateInfo =
          JSON.stringify(message.stateInfo)
            ?.replace(/(^"|"$)/g, "")
            .replace(/"/g, '""') || '""';
        nodeType = `${message.nodeType || ""}`;
      }

      if (nextMessage && nextMessage.type === "user") {
        userOriginalText = nextMessage.originalText?.replace(/"/g, '""') || "";
        userText = nextMessage.text?.replace(/"/g, '""') || "";
        // Identify userRewrittenText
        userRewrittenText = userOriginalText ? userText : '""';
        i++;
      }

      const row = [
        `"${miaText}"`,
        `"${userText}"`,
        `"${miaOriginalText}"`,
        `"${userOriginalText}"`,
        `"${miaRewrittenText}"`,
        `"${userRewrittenText}"`,
        `"${miaStateInfo}"`,
        `"${nodeType}"`,
        `"${sessionId}"`,
      ].join(",");

      csvContent += row + "\r\n";
    }
  }

  const blob = new Blob([csvContent], { type: "text/csv" });
  const url = URL.createObjectURL(blob);

  // Download CSV file
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `conversation_${sessionId}.csv`);
  document.body.appendChild(link);
  link.click();

  // Upload the conversation
  const formData = new FormData();
  formData.append("file", blob, `conversation_${sessionId}.csv`);

  // send this csv file to the backend
  try {
    const response = await axios.post(
      `${CONFIG.BASE_URL}/user_data_csv`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    Logger.debug("File uploaded successfully: ", response.data);

    sessionStorage.removeItem("chatMessages"); // Reset saved chat messages
    addMessage("bot", "Welcome back! Let's continue our conversation."); // Reset to initial greeting or similar
  } catch (error) {
    Logger.error("Failed to upload conversation: ", error);
  }
};

//submit state
export async function sendState(stateId, messageId) {
  try {
    const postData = new FormData();
    postData.append("state_id", JSON.stringify(stateId));
    postData.append("message_id", JSON.stringify(messageId));

    const response = await axios.post(`${CONFIG.BASE_URL}/state`, postData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    Logger.debug("State submitted successfully", response.data);
  } catch (error) {
    Logger.error("Error sending state:", error);
  }
}

//check if user on waitlist

export function useWaitlistStatus(userEmail) {
  const [isOnWaitlist, setIsOnWaitlist] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkUserWaitlistStatus = async () => {
      try {
        setLoading(true); // Start loading
        const response = await axios.post(
          `${CONFIG.BASE_URL}/is_user_on_waitlist`,
          { userEmail: userEmail }
        );
        setIsOnWaitlist(response.data.onWaitlist); // Update the waitlist status
      } catch (err) {
        Logger.debug("Primary server failed, trying backup server.");
        try {
          // Attempt with the backup URL if primary fails
          const backupResponse = await axios.post(
            `${CONFIG.BACKUP_URL}/is_user_on_waitlist`,
            { userEmail: userEmail }
          );
          setIsOnWaitlist(backupResponse.data.onWaitlist); // Update waitlist status from backup server
        } catch (backupErr) {
          // Handle case where both primary and backup requests fail
          setError(
            backupErr instanceof Error ? backupErr.message : String(backupErr)
          );
        }
      } finally {
        setLoading(false); // Finish loading regardless of the result
      }
    };

    if (userEmail) {
      checkUserWaitlistStatus(); // Only run the check if userEmail is provided
    }
  }, [userEmail]); // The hook depends on the userEmail value

  return { isOnWaitlist, loading, error }; // Return the necessary states
}

// send the uploaded conversation to backend
export const useUploadConversation = (
  selectedFile,
  setSelectedFile,
  addMessage
) => {
  const handleUploadChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUploadConversation = async () => {
    if (selectedFile) {
      Papa.parse(selectedFile, {
        complete: async (result) => {
          Logger.debug("Parsed CSV:", result);

          const sessionId = sessionStorage.getItem("uuid") || uuidv4();
          const sessionIdOld = result.data[0]["session_id"];
          Logger.debug("this is sessionid", result.data[0]["session_id"]);

          const userTexts = [];
          const miaTexts = [];
          const stateTrajectory = [];
          const nodeTypes = [];

          for (let row of result.data) {
            const miaText = row["Mia_text"];
            const userText = row["User_text"];
            const state = row["stateInfo"];
            const nodeType = row["nodeType"];

            if (userText) {
              addMessage("user", userText);
              userTexts.push(userText);
            }
            if (miaText) {
              addMessage("bot", miaText);
              miaTexts.push(miaText);
            }
            if (state) {
              stateTrajectory.push(state);
            }

            if (nodeType) {
              nodeTypes.push(nodeType);
            }
          }
          Logger.debug("this is node type", nodeTypes);

          try {
            const postData = new FormData();
            postData.append("sessionIdCurrent", JSON.stringify(sessionId));
            postData.append("sessionIdOld", JSON.stringify(sessionIdOld));
            postData.append("human_replies", JSON.stringify(userTexts));
            postData.append("ai_replies", JSON.stringify(miaTexts));
            postData.append(
              "state_trajectory",
              JSON.stringify(stateTrajectory)
            );
            postData.append("node_types", JSON.stringify(nodeTypes));

            await axios.post(`${CONFIG.BASE_URL}/load_from_upload`, postData, {
              headers: { "Content-Type": "multipart/form-data" },
            });

            Logger.debug("Finished processing and uploading conversation.");
          } catch (error) {
            Logger.error("Error sending conversation to backend:", error);
          }
        },
        header: true,
      });
    }
  };

  return { handleUploadChange, handleUploadConversation };
};

//send whether user give us approval to use their data
export const sendAgreeData = async (dataConsent, agreeToChat) => {
  const userEmail = localStorage.getItem("userEmail") || "";

  const formData = new FormData();
  formData.append("data_use", JSON.stringify(dataConsent));
  formData.append("agree_to_chat", JSON.stringify(agreeToChat)); 
  formData.append("user_email", JSON.stringify(userEmail));

  Logger.debug("data agree", dataConsent);

  try {
    const response = await axios.post(
      `${CONFIG.BASE_URL}/agree_data`,
      formData
    );
    return response.data;
  } catch (error) {
    Logger.error("Error sending data to the backend:", error);
    throw error;
  }
};

export const fetchUserAgreementStatus = async (userEmail) => {
  const formData = new FormData();
  formData.append("user_email", JSON.stringify(userEmail));

  try {
    const response = await axios.post(
      `${CONFIG.BASE_URL}/check_agreement`, 
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return response.data; // Return the API response data
  } catch (error) {
    Logger.error("Failed to fetch user agreement status:", error);
    throw error; // Rethrow to handle it in the component
  }
};

//for test registration
export const requestRegistrationCode = async (email) => {
  const postData = new FormData();
  postData.append("user_email", JSON.stringify(email));

  const response = await axios({
    method: "post",
    url: `${CONFIG.BASE_URL}/request_code`,
    data: postData,
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response.data;
};


