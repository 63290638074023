import React, { useState, useEffect } from "react";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { StatusCodes } from "http-status-codes";
import {usePsyfyClient} from "../hooks/psyfyClient";
import useBodyClass from './useBodyClass';
import TermsAndConditions from "../content/TermsContent";
import Logger from "../utils/logger";


const SignUpForm = () => {
  const { checkUserIP, signupUser} = usePsyfyClient();
  const [clientEmail, setClientEmail] = useState("");
  const [clientPassword, setClientPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isOver18, setIsOver18] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [capacityReached, setCapacityReached] = useState(false);
  const [isRegionBlocked, setIsRegionBlocked] = useState(false);
  const user = "user";
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false); 
  useBodyClass('login-page');

  const TermsModal: React.FC<TermsModalProps> = ({ onClose, onAgree }) => {
    return (
      <div className="modal">
        <div className="modal-content">
          <TermsAndConditions />
          <div className="modal-actions">
            <div className="button-container">
              <button className="btn btn-primary" onClick={onAgree}>
                Agree
              </button>
              <button className="btn btn-danger" onClick={onClose}>
              Disagree
            </button>
            </div>
            
          </div>
        </div>
      </div>
    );
  };

  const LoadingPopup = () => (
    <div className="loading-backdrop">
     <div className="loading-popup">
      <div className="loading-content">
      {errorMessage ? (
          <>
            <p>{errorMessage}</p>
            <button onClick={() => window.location.reload()}>Retry</button>
          </>
        ) : (
          <p>Please wait...</p>
        )}
        {/* <p>Please wait...</p> */}
      </div>
    </div>
    </div>
  );


  // Handle checkbox directly to manage Terms Modal visibility
  const handleTermsCheckboxChange = () => {
    setShowTermsModal(!showTermsModal);
  };

  const handleAgree = async () => {
    setAgreedToTerms(true);
    setShowTermsModal(false);
  };

  const handleDisagree = () => {
    setShowTermsModal(false);
    setAgreedToTerms(false);
  };

  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Reset previous error messages.
    setErrorMessage("");

    if (!isOver18) {
      setErrorMessage("You must confirm that you are over 18 years old.");
      return;
    }

    if (!agreedToTerms) {
      setErrorMessage("You must agree to the terms and conditions to sign up.");
      return;
    }

    setLoading(true);

    const postData = new FormData();
    postData.append("clientEmail", clientEmail);
    postData.append("pw1", clientPassword);
    postData.append("agreedToTerms", JSON.stringify(agreedToTerms));

    let data;

    setIsButtonDisabled(true); // Disable the button on form submit
    setTimeout(() => {
      setIsButtonDisabled(false); // Re-enable the button after 2 minutes
      setLoading(false);
    }, 50000); 

    try {

      const response = await signupUser(postData);      

    
        // if (!response.data) {
        //     throw new Error("No data received from the server. Check the server response format.");
        // }
      const data = response.data;

      // setLoading(false);
      
    switch (response.status) {
      case 200:
          // Success case, data should have all required fields
          localStorage.setItem("userEmail", data.clientEmail);
          localStorage.setItem("access_token", data.access_token);
          navigate(capacityReached ? "/profile" : "/chatbot");
          break;
      case 400:
          setErrorMessage("Email validation failed. Please provide a deliverable email address.");
          break;
      case 402:
          setErrorMessage("Email not deliverable. Please provide a deliverable email address.");
          break;
      case 403:
          setErrorMessage("User already exists. Please try logging in.");
          break;
      case 409:
          setErrorMessage("Wrong password format. Please follow the required format.");
          break;
      default:
          setErrorMessage("There was a problem with your signup attempt. Please check your email and try again.");
          break;
  }
} catch (error) {
  Logger.error("Error during signup:", error);
  if (axios.isAxiosError(error)) { 
    if (error.response) {
        // Handling errors based on the HTTP status code from the server response
        switch (error.response.status) {
            case 400:
                setErrorMessage("Bad request. " + error.response.data.message);
                break;
            case 402:
                setErrorMessage("Email not deliverable. Please provide a deliverable email address.");
                break;
            case 403:
                setErrorMessage("Forbidden. " + error.response.data.message);
                break;
            case 409:
                setErrorMessage("Conflict. " + error.response.data.message);
                break;
            default:
                setErrorMessage("An unexpected error occurred. Please try again later.");
                break;
        }
    } else {
        // Handle network errors or errors where the response is not available
        setErrorMessage("An error occurred during signup. Please check your network and try again later.");
    }
  }
}
};


  if (isRegionBlocked) {
    return (
      <div className="centered-wrapper">
        <div className="block-message">
          <p>{errorMessage}</p>
          <p>
            Contact <a href="mailto:psyfy@psyfy.ai">psyfy@psyfy.ai</a> if you
            want to try our service.
          </p>
        </div>
      </div>
    );
  }

  if (showTermsModal) {
    return <TermsModal onClose={handleDisagree} onAgree={handleAgree} />;
  }

  return (
    <div className="signup-page">
       {loading && <LoadingPopup />}
       
      <div className="form">
        {capacityReached && ( // Conditionally render the waitlist message
          <p>
            We have currently reached our testing capacity. You have
            been placed on a waiting list and will be notified via email when a
            spot becomes available. Thank you for your patience.
          </p>
  
        )}
        <form className="login-form" onSubmit={handleSubmit}>
          <p
            // style={{
            //   color: "#31304D",
            //   marginBottom: "30px",
            //   marginRight: "40px",
            //   fontSize: "16px",
            // }}
          >
            Please ensure your password consists of a minimum of six characters
            including at least one numeric digit, one uppercase letter, and one
            special symbol.
          </p>
          <input
            type="text"
            placeholder="your email"
            value={clientEmail}
            onChange={(e) => setClientEmail(e.target.value)}
          />

          <input
            type="password"
            placeholder="password"
            value={clientPassword}
            onChange={(e) => setClientPassword(e.target.value)}
          />

          <div className="checkbox-container">
            <input
              className="checkbox-field"
              type="checkbox"
              checked={agreedToTerms}
              readOnly={showTermsModal}
              onChange={handleTermsCheckboxChange} 
            />
            <label className="checkbox-label">
              I agree to the Terms and Conditions
            </label>
          </div>

          <div className="checkbox-container">
            <input
              className="checkbox-field"
              type="checkbox"
              checked={isOver18}
              onChange={(e) => setIsOver18(e.target.checked)}
            />
            <label className="checkbox-label">
              I declare that I am over 18 years old
            </label>
          </div>

          <button type="submit" disabled={isButtonDisabled}>SignUp</button>

          <div>
            <p className="errorMessage">{errorMessage}</p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUpForm;
