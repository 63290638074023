import React from "react";
import MessageItem from "./MessageItem";

const MessageList = ({
  messages,
  onRating,
  endOfMessagesRef,
  onRewriteInitiate,
  isExperiment,
  isTyping,
}) => {
  return (
    <div className="message-list">
      {/* {messages.map((message) => ( */}
      {Array.isArray(messages) &&
        messages.map((message) => (
          <MessageItem
            key={message.id}
            message={message}
            onRating={onRating}
            onRewriteInitiate={onRewriteInitiate}
            isExperiment={isExperiment}
          />
        ))}

{isTyping && (
              <div className="message bot">
                <div className="text">Typing...</div>
              </div>
            )}


      <div ref={endOfMessagesRef} />
    </div>
  );
};

export default MessageList;
