import "normalize.css/normalize.css";
import "./css/style_chatbot.scss";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Intro from "./components/Intro";
import ResearchPage from "./components/ResearchPage";
import LoginPage from "./components/LoginPage";
import HomePageHeader from "./components/HomePageHeader";
import ProfilePage from "./components/ProfilePage";
import SignUpPage from "./components/SignUpPage";
import Chatbot from "./components/Chatbot/ChatbotIndex";
import EmotionRegulationToolkit from "./components/BehavioralActivation";
import PrivacyPolicyPage from "./content/PrivacyPolicyPage";
import TermsConditionPage from "./content/TermsConditionPage";
import TestRegistration from "./components/TestRegistration";
import PasswordResetRequestForm from "./components/ResetPasswords";
import ResetPasswordPage from "./components/ResetPasswordPage";
import Header from "./components/Header";
import UnsubscribePage from "./components/unsubscribe";
import SuccessPage from "./components/PaymentSuccess"
import StripeCheckoutComponent from './components/StripeCheckoutComponent'; 
import stripePromise from './hooks/stripe'; 
import { Elements } from '@stripe/react-stripe-js';
import BuyCredit from "./components/PurchasePlans";
import Guidelines from "./components/Guideline";
import { Helmet } from 'react-helmet';


const App = () => {
  // useEffect(() => {
  //   const currentHost = window.location.host;
  //   const pathName = window.location.pathname + window.location.search + window.location.hash;
  //   const targetHost = (isMobile || isTablet) ? 'mobile.psyfy.ai' : 'www.psyfy.ai';

  //   if (currentHost !== targetHost) {
  //     window.location.href = `${window.location.protocol}//${targetHost}${pathName}`;
  //   }
  // }, []);



  return (
    <Router>
      <div>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
    
      </Helmet>

        <header className="float-header">
        <Header />
        </header>



        <Routes>
          <Route path="/" element={<HomePageHeader />} />
          <Route path="/intro" element={<Intro />} />
          <Route path="/research" element={<ResearchPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/chatbot" element={<Chatbot />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/signup" element={<SignUpPage />} />
     
          {/* <Route path="/emotionReg" element={<EmotionRegulationToolkit />} /> */}
          <Route path="/privacy" element={<PrivacyPolicyPage />} />
          <Route path="/terms" element={<TermsConditionPage />} />
          <Route path="/pwReset" element={<PasswordResetRequestForm />} />
          <Route path="/reset_password" element={<ResetPasswordPage />} />
          <Route path="/unsubscribe" element={<UnsubscribePage />} />
          <Route path="/paymentsuccess" element={<SuccessPage />} />
          <Route path="/credits" element={<BuyCredit />} />
          <Route path="/checkout" element={<Elements stripe={stripePromise}><StripeCheckoutComponent /></Elements>} />  
          <Route path="/guideline" element={<Guidelines />} />
        </Routes>
        {/* <FrontPageFooter /> */}
      </div>

     
    </Router>
  );
};

export default App;
