import React, { useState } from "react";
import profile from "../assets/profile.jpg";
import memory from "../assets/memory.jpg";
import color_brain from "../assets/color_brain.jpg";
import brain from "../assets/brain.jpg";

const IntroBlocks: React.FC = () => {
  return (
    <div className="blocks-container">
      <div className="block block-profile">
        <div className="text-area">
          PsyFy also offers a user profile. In this profile, you can track your
          progress, see a summary of each conversation, monitor your mood
          fluctuations, and stay updated on the strategies you are learning.
          With this innovative tool, you can easily reflect on your growth and
          see how your efforts are positively impacting your mental health
          journey.
        </div>
        <img src={profile} alt="Description of Image 1" />
      </div>
      <div className="block block-memory">
        <div className="text-area">
          PsyFy has a built-in memory system, designed to enhance user
          experience like never before. This unique feature allows PsyFy to
          remember important details about the your life experiences, goals you
          have set, and preferences, creating a personalized and tailored
          interaction. By keeping track of this information, PsyFy can provide
          more relevant and helpful responses. Say goodbye to repetitive
          interactions and hello to a more intuitive and seamless chatbot
          experience.
        </div>
        <img src={memory} alt="Description of Image 2" />
      </div>

      <img
        className="brain-image"
        src={color_brain}
        alt="Description of Image 2"
      />

      <div className="block block-strategy">
        {/* <img src={brain} alt="Description of Image 2" /> */}
        <div className="text-area">
          PsyFy offers personalized coaching in Cognitive Behavior Therapy (CBT)
          strategies, designed by our clinical psychologist David Preece, to
          help you cope with stress and improve your well-being. These
          strategies are designed to help you improve your well-being, build
          healthier habits, and achieve your personal and professional goals.
          With customizable feedback and reminders, PsyFy allows you to stay on
          track with your journey towards a more fulfilling and balanced life.
          <br></br>
          <br></br>
          The strategies that PsyFy teaches are 'transdiagnostic', which means
          they are relevant and helpful across a wide range of different mental
          health issues. This allows PsyFy to be flexible in working with you
          across many areas in your life. By working with PsyFy you can gain a
          deeper understanding of your emotions, thoughts, and behaviors,
          develop healthy coping mechanisms, and improve your overall
          well-being. With PsyFy as your guide, you can unlock the advantages of
          these strategies and discover new ways to overcome obstacles and
          thrive. Start your journey towards a happier, healthier you today.
        </div>
      </div>
    </div>
  );
};

export default IntroBlocks;
