import axios from 'axios';
import CONFIG from "../config";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Logger from "../utils/logger";



export const usePsyfyClient = () => {
  
    const navigate = useNavigate();


    const checkUserCredits = async () => {
      const userEmail = localStorage.getItem("userEmail");
      if (!userEmail) {
        navigate("/login");
        return;
      }
  
      try {
        const response = await axios.post(`${CONFIG.BASE_URL}/check_credits`, { user_email: userEmail });
   
        
        if (response.status !== 200) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        if (response.data.user_credits === 0) {
          // Handle credit-depletion logic here or by returning necessary info
          return false;
        }
        
        return true;
      } catch (error) {
        Logger.error("Error fetching user credits: ", error);
        // Return or handle 
        return false;
      }
    };

 

  const checkAndSyncSession = async (userEmail, sessionId) => {
    try {
      const postData = new FormData();
      postData.append("user_email", JSON.stringify(userEmail));
      postData.append("session_id_value", JSON.stringify(sessionId));
      const accessToken = localStorage.getItem("access_token");

      Logger.debug("sync conversation", accessToken)

      // Initialize headers object
      let headers = { "Content-Type": "multipart/form-data" };

      // Add Authorization only if accessToken exists
     if (accessToken) {
      headers["Authorization"] = `Bearer ${accessToken}`;
    }
  
  
      const response = await axios({
        url: `${CONFIG.BASE_URL}/user_session`,
        method: 'POST',
       // headers: { 'Content-Type': 'multipart/form-data' },
        data: postData,
        headers: headers,
      });
  
      const data = response.data;
      if (data.session_id) {
        return {
          session_id: data.session_id,
          error: false,
          message: 'Session synced successfully.'
        };
      } else {
        return {
          session_id: null,
          error: true,
          message: 'Session not found. User might not be logged.'
        };
      }
    } catch (err) {
      Logger.debug('Error checking/syncing session:', err);
      return {
        session_id: null,
        error: true,
        message: `Error checking/syncing session: ${err.message}`
      };
    };
    
  };

  const fetchConversationHistory = async (sessionId) => {

    Logger.debug("load conversation history")
    const postData = new FormData();
    postData.append("session_id", JSON.stringify(sessionId));
    const accessToken = localStorage.getItem("access_token");

    // Initialize headers object
    let headers = { "Content-Type": "multipart/form-data" };

     // Add Authorization only if accessToken exists
     if (accessToken) {
      headers["Authorization"] = `Bearer ${accessToken}`;
    }
  
  
    try {
      const response = await axios({
        method: "post",
        url: `${CONFIG.BASE_URL}/conversation_history`,
        data: postData,
        headers: headers,
      });
      
      return response.data; // Return the data directly from the function

     
    } catch (error) {
      // It's often useful to throw an error further to be handled by the caller
      throw new Error(`Failed to fetch conversation history: ${error.message}`);
    }
    
  };

  const checkIfRepeatUser = async () => {
    const userEmail = localStorage.getItem("userEmail") || "";
    const postData = new FormData();
    postData.append("user_email", JSON.stringify(userEmail));
  
    try {
      const response = await axios({
        method: "post",
        url: `${CONFIG.BASE_URL}/repeat_user`,
        data: postData,
        headers: { "Content-Type": "multipart/form-data" },
      });
  
      const data = response.data;
      return data.isRepeatUser;
    } catch (error) {

      Logger.debug("Error checking repeat user on primary server:");

     
    }
  };
  

const sendBotMessage = async (data) => {
// async function sendBotMessage(data) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
  
    const accessToken = localStorage.getItem("access_token");
  
    // Add Authorization only if accessToken exists
    if (accessToken) {
      headers["Authorization"] = `Bearer ${accessToken}`;
    }
  
    try {
      // First try with the primary URL
      const response = await axios.post(`${CONFIG.BASE_URL}/reply`, data, {
        headers,
      });
      Logger.debug("this is response data 22222", response.data);


      return response.data;
    } catch (error) {

      Logger.debug("authentication error", error);
    
    }
  }



  const prepareAndSendBotMessage = async ( messages,
    humanMessage,
    messageIndex,
    humanTimestamp) => {
    const userEmail = localStorage.getItem("userEmail") || "";
    const sessionId = sessionStorage.getItem("uuid");

    const aiReplies = messages
      .filter((msg) => msg.type === "bot")
      .map((msg) => msg.text);
    let humanReplies = messages
      .filter((msg) => msg.type === "user")
      .map((msg) => msg.text);
    humanReplies.push(humanMessage); // Append the latest user message
  
   
    const postData = new FormData();
    Logger.debug("this is human time stamp", humanTimestamp);
    //encrypt human turns
    postData.append("human_turns", JSON.stringify(humanReplies));
    postData.append("ai_turns", JSON.stringify(aiReplies));
    postData.append("human_timestamp", JSON.stringify(humanTimestamp));
    postData.append("api_key_value", JSON.stringify("aurora"));
    postData.append("session_id_value", JSON.stringify(sessionId));
    postData.append("user_email", JSON.stringify(userEmail));
    postData.append("user_correction", JSON.stringify(null));
    postData.append("message_id_db", JSON.stringify(messageIndex));
    
  
    return sendBotMessage(postData);
  }

  const prepareAndSendBotMessageInitial = async (messageIndex) => {
    const userEmail = localStorage.getItem("userEmail") || "";
    const sessionId = sessionStorage.getItem("uuid");

  
    const postData = new FormData();
    postData.append("api_key_value", JSON.stringify("aurora"));
    postData.append("session_id_value", JSON.stringify(sessionId));
    postData.append("user_email", JSON.stringify(userEmail));
    postData.append("user_correction", JSON.stringify(null));
    postData.append("message_id_db", JSON.stringify(messageIndex));
    
  
    return sendBotMessage(postData);
  }
  
  
  const sendRating = async ( ratedMessage,
    ratingType,
    messageId,
    sessionId) => {
    try {
      const postData = new FormData();
  
      // Add condition for up or down rating directly here
      postData.append("user_rating_down", JSON.stringify(ratingType === "down"));
      postData.append("user_rating_up", JSON.stringify(ratingType === "up"));
      postData.append("rating_id", JSON.stringify(messageId));
      postData.append("session_id_value", JSON.stringify(sessionId));
  
      const response = await axios.post(`${CONFIG.BASE_URL}/rating`, postData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
  
      // If necessary, update local state or perform actions based on server's response
      Logger.debug("Rating submitted successfully", response.data);
    } catch (error) {
      Logger.error("Error sending rating:", error);
    }
  }
  
  const requestSummarySurrogate = async () => {
    const userEmail = localStorage.getItem("userEmail") || "";
    const sessionId = sessionStorage.getItem("uuid");
  
    const postData = new FormData();
    postData.append("user_email", JSON.stringify(userEmail));
    postData.append("session_id_value", JSON.stringify(sessionId));
    const accessToken = localStorage.getItem("access_token");

     // Initialize headers object
    let headers = { "Content-Type": "multipart/form-data" };

    // Add Authorization only if accessToken exists
    if (accessToken) {
      headers["Authorization"] = `Bearer ${accessToken}`;
    }
  
    try {
      const response = await axios({
        method: "post",
        url: `${CONFIG.BASE_URL}/update_memory`,
        data: postData,
        headers: headers,
       // headers: { "Content-Type": "multipart/form-data" },
      });
  
      Logger.debug("Summary request response:", response.data);
      return response.data;
    } catch (error) {
      Logger.debug("Primary server error. Trying backup server:", error);
    }
  };

  const useWaitlistStatus = async (userEmail) => {

    const [isOnWaitlist, setIsOnWaitlist] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const checkUserWaitlistStatus = async () => {
        try {
          setLoading(true); // Start loading
          const response = await axios.post(
            `${CONFIG.BASE_URL}/is_user_on_waitlist`,
            { userEmail: userEmail }
          );
          setIsOnWaitlist(response.data.onWaitlist); // Update the waitlist status
        } catch (err) {
          Logger.debug("Primary server failed", err);
        } finally {
          setLoading(false); // Finish loading regardless of the result
        }
      };
  
      if (userEmail) {
        checkUserWaitlistStatus(); // Only run the check if userEmail is provided
      }
    }, [userEmail]); // The hook depends on the userEmail value
  
    return { isOnWaitlist, loading, error }; // Return the necessary states
  };

  const loginUser = async (clientEmail, clientPassword) => {
    const postData = new FormData();
    postData.append("clientEmail_login", clientEmail);
    postData.append("user_pw", clientPassword);
    
    try {
        const response = await axios.post(`${CONFIG.BASE_URL}/login_submit`, postData);
        return response;
    } catch (error) {
        Logger.debug("Primary server failed");
        throw error
    }
};

const checkUserIP = async (user) => {
  try {
      const response = await axios.post(`${CONFIG.BASE_URL}/check_ip`, { user });
      if (response.status !== 200) {
          throw new Error(`HTTP Status: ${response.status}`);
      }
      return response.data;
  } catch (error) {
      Logger.debug("Error checking user IP:", error);
      throw error;  // Rethrowing the error to be handled by caller
  }
};



const signupUser = async (postData) => {
  try {
    const response = await axios.post(`${CONFIG.BASE_URL}/signup_submit`, postData);


    return response.data;  // Return the successful data

  } catch (error) {
    // On primary error try the backup URL
    Logger.debug("signing up", error);
    throw error; 
  }
};


const signupUserTest = async (postData) => {
  try {
    const response = await axios.post(`${CONFIG.BASE_URL}/signup_submit_test`, postData);
    return response.data;  // Return the successful data
  } catch (error) {
    // On primary error try the backup URL
    Logger.debug("signing up", error);
    throw error; 
  }
};


const requestPasswordReset = async (email) => {
  try {
    const postData = new FormData();
    postData.append("user_email", JSON.stringify(email));

    const response = await axios.post(`${CONFIG.BASE_URL}/request_reset`, postData);
    return response;
  } catch (error) {
    // Optionally handle errors or rethrow them
    Logger.error("Error in requestPasswordReset:", error);
    throw error;
  }
};

const requestRegistrationCode = async (email) => {
  const postData = new FormData();
  postData.append("user_email", JSON.stringify(email));

  const response = await axios({
    method: "post",
    url: `${CONFIG.BASE_URL}/request_code`,
    data: postData,
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response.data;
};

const fetchUserData = async (email) => {
  const postData = new FormData();
  postData.append("userEmail", JSON.stringify(email));

  try {
    const response = await axios({
      method: "post",
      url: `${CONFIG.BASE_URL}/get_user_info`,
      data: postData,
      headers: { "Content-Type": "multipart/form-data" },
    });

    // Handle any additional data manipulation or formatting here if necessary
    return response.data;
  } catch (error) {
    // Error handling, might want to pass the error up or handle it according to your needs
    throw new Error(`Failed to fetch user data: ${error.message}`);
  }
};

const reasonMappings = {
  tooManyEmails: 1,
  notInterested: 2,
  contentNotUseful: 3,
  tooExpensive: 4,
  technicalIssues: 5,
  foundAlternative: 6,
  privacyConcerns: 7,
  other: 8  // Assuming 'other' is mapped to 8
};


const handleUnsubscribe = async (userEmail, reasons) => {
  try {
      const postData = new FormData();
      postData.append("userEmail", JSON.stringify(userEmail));
      
      
      // Get the reason number from the reasonMappings
       // Determine which reason is selected
      let selectedReasonNumbers = [];
        Object.keys(reasons).forEach(key => {
            if (reasons[key] === true && reasonMappings[key]) {
                selectedReasonNumbers.push(reasonMappings[key]);
            }
        });

      
      postData.append("reasons", JSON.stringify(selectedReasonNumbers));  

      if (reasons.otherCheckbox && reasons.otherText.trim() !== '') {
        postData.append("otherReason", JSON.stringify(reasons.otherText)); 
    }



      const response = await axios({
          url: `${CONFIG.BASE_URL}/unsubscribe`,
          method: 'POST',
          headers: { 'Content-Type': 'multipart/form-data' },
          data: postData,
      });

      alert(response.data.message); // Display the backend message
  } catch (error) {
      alert('Error unsubscribing, please try again.');
      Logger.error('Unsubscribe error:', error);
  }
};



const handlePayment = async (selectedTab) => {
  const userEmail = localStorage.getItem("userEmail") || "";
  const paymentMethodId = selectedTab.paymentMethodId; // Assuming this is fetched/stored elsewhere
  const amount = parseInt(selectedTab.price.replace('$', '')) * 100; // Convert the price to cen


  const postData = new FormData();
  postData.append("user_email", JSON.stringify(userEmail));
  postData.append("paymentMethodId", paymentMethodId);
  postData.append("amount", amount.toString());
  postData.append("credit_plan", selectedTab.plan);


  const endpoint = `${CONFIG.BASE_URL}/payment`;

  try {
    const { data } = await axios({
      method: "post",
      url: endpoint,
      data: postData,
      headers: { "Content-Type": "multipart/form-data" }
    });
   Logger.debug('Payment successful:', data);
    if (data.status === 'success') {
      navigate("/paymentsuccess", { state: { selectedTab } }); // Navigate to success page
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      Logger.error('Payment failed:', error.response?.data || 'No response data');
    } else if (error instanceof Error) {
      Logger.error('Payment failed:', error.message);
    } else {
      Logger.error('Payment failed with unknown error type', error);
    }
  }
};


const fetchSubscription = async (userEmail, setSelectedTab) => {
  const postData = new FormData();
  postData.append("user_email", JSON.stringify(userEmail));

  try {
    const response = await axios({
      url: `${CONFIG.BASE_URL}/get_subscription`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: postData,
    });

    if (response.data.isSubscribed) {
      setSelectedTab(prev => ({
        ...prev,
        currentPlan: response.data.pay_plan,
        isSubscribed: true
      }));
 
    } else {
      setSelectedTab(prev => ({
        ...prev,
        currentPlan: 'Not subscribed',
        isSubscribed: false
      }));
    }
  } catch (error) {
    Logger.error('Failed to fetch subscription:', error);
  }
};


const fetchUserCredits = async () => {
  const userEmail = localStorage.getItem("userEmail") || "";

  const postData = new FormData();
  postData.append("user_email", JSON.stringify(userEmail));

  if (!userEmail) {
    Logger.error("User email not found.");
    return null;  // Ensure we return null if there's no email.
  }

  try {
     const response = await fetch(`${CONFIG.BASE_URL}/check_credits`, {
        method: "POST",
        body: postData,
      });
    
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json(); // Parsing the JSON response body
    return data.user_credits || null;  // Safely return user_credits or null if undefined
  
    }  catch (error) {
      Logger.error("Error fetching user credits:", error);
      return null;  // Return null in case of an error to handle it gracefully
    }
  };


  const handleLanguageChange = async (selectedLanguage, setUserLanguage) => {
    const userEmail = localStorage.getItem("userEmail") || "";
    const postData = new FormData();
    postData.append("user_email", JSON.stringify(userEmail));
    postData.append("language", JSON.stringify(selectedLanguage));

    try {
      const response = await axios({
        method: "post",
        url: `${CONFIG.BASE_URL}/set_language`,
        data: postData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      Logger.debug("Language set successfully:", response.data);
      setUserLanguage(selectedLanguage); // Update language state in the component
    } catch (error) {
      Logger.debug("Error setting language:", error);
    }
};



return { checkUserCredits, checkAndSyncSession, fetchConversationHistory, checkIfRepeatUser, sendBotMessage, prepareAndSendBotMessage, useWaitlistStatus, sendRating, requestSummarySurrogate, loginUser, checkUserIP, signupUser, requestPasswordReset, signupUserTest, requestRegistrationCode, fetchUserData, handleUnsubscribe, handlePayment, fetchSubscription, prepareAndSendBotMessageInitial, fetchUserCredits, handleLanguageChange};


 
}
