import React, { useState, useEffect, ChangeEvent} from "react";
import Header from "./Header";
import axios from "axios";
import CONFIG from "../config";
import { useNavigate } from "react-router-dom";
import { usePsyfyClient } from "../hooks/psyfyClient";
import Switch from '@mui/material/Switch';
import Logger from "../utils/logger";



// Define a type for the tab state
type TabState = {
  plan: string;
  price: string;
  tokens: number;
  isSubscribed: boolean; 
  currentPlan: string;
  isNewPlanSelected: boolean;
};

// Define a type for credit plan details
type CreditPlanDetails = {
  plan: string;
  price: string;
  tokens: number;
};

const BuyCredit = () => {
 
  const [selectedTab, setSelectedTab] = useState<TabState>({
    plan: '',
    price: '',
    tokens: 0,
    isSubscribed: false,
    currentPlan: '',
    isNewPlanSelected: false
  }); 
  const navigate = useNavigate(); // Use navigate for navigation
  const userEmail = localStorage.getItem("userEmail");
  const { fetchSubscription, fetchUserCredits} = usePsyfyClient();
  const [autoRenew, setAutoRenew] = useState(false);
  const [userCredits, setUserCredits] = useState<number | null>(null);

  useEffect(() => {
    // Check if the user is logged in
    const isLoggedIn = sessionStorage.getItem("isLoggedIn"); // Adjust this according to your auth logic

    if (!userEmail) {
      navigate("/login"); // Redirect to login page if not logged in
    }
  }, [navigate]);

  useEffect(() => {
    if (userEmail) {
      fetchSubscription(userEmail, setSelectedTab);
    }


  }, [userEmail, setSelectedTab]);

  useEffect(() => {
    const updateCredits = async () => {
      
      const credits = await fetchUserCredits();


      setUserCredits(credits);


    };

    if (localStorage.getItem("userEmail")) {
      updateCredits();
    } else {
      Logger.debug("User Email not found, can't fetch credits.");
    }
  }, []);


  const handleAutoRenewToggle = async (
    newAutoRenewState: boolean,
    userEmail: string | null,
    setAutoRenew: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (!userEmail) {
      Logger.error("User email is null, cannot update auto-renew");
      return;
    }
  
    setAutoRenew(newAutoRenewState);  // Update the local state
  
    try {
      const response = await axios.post(`${CONFIG.BASE_URL}/autorenew`, {
        userEmail,
        autoRenew: newAutoRenewState
      });
  
      if (!response.data.success) {
        throw new Error('Failed to update auto-renew');
      }
      Logger.debug("Auto-renew updated successfully");
    } catch (error) {
      Logger.error('Error updating auto-renew:', error);
      setAutoRenew(!newAutoRenewState); // Revert to previous state on error
    }
  };
  
  // Annotate the parameters with types
  const handleTabClick = (plan: string, price: string, tokens: number) => {
    //setSelectedTab({  plan, price, tokens });
    setSelectedTab(prev => ({ ...prev, plan, price, tokens, isNewPlanSelected: plan !== prev.currentPlan}));
  };

  
  const handleBuyPlan = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const path = "/checkout";
    if (selectedTab.isSubscribed && !selectedTab.isNewPlanSelected) {
      alert('Please select a different plan to upgrade.'); // Notify user to select a new plan
      return;
    }
    navigate(path, { state: { selectedTab } });


  };
  
  //Event handler using imported function
  const toggleAutoRenew = (event: ChangeEvent<HTMLInputElement>) => {
    handleAutoRenewToggle(event.target.checked, userEmail, setAutoRenew);
  };

  const handleBuyCredits = async (creditPlanDetails: CreditPlanDetails) => {
    const { plan, price, tokens } = creditPlanDetails;
    const path = "/checkout";
  
    // Update the selectedTab state with the new plan details
    setSelectedTab(prev => ({ 
      ...prev, 
      plan, 
      price, 
      tokens, 
      isNewPlanSelected: plan !== prev.currentPlan
    }));
  
    // Navigate to the checkout page with the updated state
    navigate(path, { state: { selectedTab: { ...selectedTab, plan, price, tokens } } });
  };


  


  return (

    <div className="buy-credit">
  <Header />



  <div className="top-info">
  
  <div className="current-plan" >
    Current Plan: {selectedTab.currentPlan || 'No Plan Selected'}
    {userCredits !== null ? ` - Your Credits: ${userCredits}` : ''}

  </div>
  <div className="auto-renew-toggle">
        <span>Auto Renew Plan:</span>
        {/* <Switch checked={autoRenew} onChange={handleAutoRenewToggle} /> */}
        <Switch 
  checked={autoRenew}
  onChange={(event) => {
    if (userEmail) {
      handleAutoRenewToggle(event.target.checked, userEmail, setAutoRenew);
    } else {
      Logger.error("User email is not available.");
    }
  }}
/>


      </div>
  </div>

  <div className="buy-credit-content">
  <p>Credit Logic: each chatbot response will use one credit.</p>
    <div className="tab-buttons">
      <div className="pricing-container">
        {[
         { name: 'Basic', price: '$5', credits: 150, color: "white"},
         { name: 'Standard', price: '$10', credits: 350, color: '#262FBB'},
         { name: 'Pro', price: '$20', credits: 800, color: '#C785FD'}
        ].map(plan => (
          <button key={plan.name} 
                  className={`${plan.name.toLowerCase()} ${selectedTab.plan === plan.name ? "active" : ""} ${selectedTab.currentPlan === plan.name ? "deactivated" : ""}`}
                  onClick={() => handleTabClick(plan.name, plan.price, plan.credits)}
                  disabled={selectedTab.currentPlan === plan.name}>
            <h1 style={{ color: plan.color }}>{plan.name}</h1>
            <div className="pricing" style={{ color: plan.color }}>{plan.price}</div>
            <div className="month">/Month</div>
            <div className="tokens">{plan.credits} credits</div>
          </button>
        ))}
        </div>

      <div className="extra-credits">
        <button onClick={() => handleBuyCredits({
            plan: 'Supply1', price: '$15', tokens: 400
          })}>350 Credits $15</button>
        <button onClick={() => handleBuyCredits({
            plan: 'Supply2', price: '$25', tokens: 800
          })}>800 Credits $25</button>
   
      </div>

     
      <button className="buy-credit-button" onClick={handleBuyPlan} disabled={selectedTab.isSubscribed && !selectedTab.isNewPlanSelected} >
        
        {selectedTab.isSubscribed ? 'Change Plan' : 'Purchase Plan'}
      </button>
    </div>
  </div>
</div>
 

 
  );
};

export default BuyCredit;



