import React, { useState, useEffect } from "react";
import Header from "./Header";
import ChatButton from "./ChatButton";
import { useWaitlistStatus } from "../hooks/chatbotService";
import { useNavigate } from "react-router-dom";
import FrontPageFooter from "./FrontPageFooter";
import CONFIG from "../config";
import { usePsyfyClient } from "../hooks/psyfyClient";
import Logger from "../utils/logger"
import { parse, format } from 'date-fns';

interface CreditTabProps {
  credits: number | null; // Allow `credits` to be `null`
  // userPlan: string;
}

const cleanString = (input: string): string => {
  return input.replace(/[^\x20-\x7E]+/g, '');
};

const formatDate = (dateTimeString: string) => {

const cleanedString = cleanString(dateTimeString);



  try {
    const formatString = 'M/d/yyyy, h:mm:ss a'; // Matches "7/6/2024, 10:32:34 PM"
    const parsedDate = parse(cleanedString, formatString, new Date());

    return format(parsedDate, 'MMM/dd/yyyy, h:mm a');
  } catch (error) {

    return 'Invalid Date';
  }
};

const ProfilePage = () => {
  const [summaries, setSummaries] = useState<Summary[]>([]);
  const [loading, setLoading] = useState(false);
  const [expandedSummaryId, setExpandedSummaryId] = useState<
    string | number | null
  >(null);
  const userEmail = localStorage.getItem("userEmail");
  const navigate = useNavigate();
  const { isOnWaitlist } = useWaitlistStatus(userEmail);
  const [userCredits, setUserCredits] = useState<number | null>(null);
  const { fetchUserCredits } = usePsyfyClient();
  //const [userPlan, setUserPlan] = useState("No Subscription");

  useEffect(() => {
    getSummary();
    Logger.debug("user on waitlist", isOnWaitlist);
  }, []);

  useEffect(() => {
    if (!userEmail) {
      navigate("/login"); // Redirect to login if no userEmail found.
      // Removed the redirection when `isOnWaitlist` is false because it seems unnecessary.
    }
  }, [navigate, userEmail]);


  useEffect(() => {
    const updateCredits = async () => {
      
      const credits = await fetchUserCredits();
      Logger.debug("get credits", credits)

      setUserCredits(credits);


    };

    if (localStorage.getItem("userEmail")) {
      updateCredits();
    } else {
      Logger.debug("User Email not found, can't fetch credits.");
    }
  }, []);


  const toggleSummary = (id: string | number): void => {
    setExpandedSummaryId(expandedSummaryId === id ? null : id);
  };

  const CreditsTab: React.FC<CreditTabProps> = ({ credits }) => {
    return (
      <div className="credits-tab">
        <p className="font">Your Credits: {credits}</p>
        {/* <p className="font">Your Plan: {userPlan}</p> */}
      </div>
    );
  };

  const getSummary = async () => {
    setLoading(true);

    // Check if summaries are cached
    const cachedSummaries = localStorage.getItem("cachedSummaries");
    if (cachedSummaries) {
      setSummaries(JSON.parse(cachedSummaries));
      setLoading(false);
      return;
    }
    // Convert fetching logic to use FormData
    const sessionIds = JSON.parse(localStorage.getItem("sessions") || "[]");
    const userEmail = localStorage.getItem("userEmail");
    const accessToken = localStorage.getItem("access_token");

    if (sessionIds.length === 0) {
      const sessionId = sessionStorage.getItem("uuid");
      if (sessionId) {
        sessionIds.push(sessionId);
      }
    }

    const formData = new FormData();

    formData.append("session_ids_list", JSON.stringify(sessionIds));
    formData.append("user_email", JSON.stringify(userEmail));

    try {

      const response = await fetch(`${CONFIG.BASE_URL}/summary`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${accessToken}` // Ensures the Authorization header is set
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: ResponseData = await response.json();

      const isDataValid =
        data && typeof data === "object" && !Array.isArray(data);

      if (isDataValid) {
        const tempSummaries = Object.entries(data).reduce<Summary[]>(
          (acc, [key, value]) => {
            if (key !== "status" && value && value.summary && value.time) {
              acc.push({
                id: key,
                summary: value.summary,
                time: new Date(value.time).toLocaleString(),
              });
            }
            return acc;
          },
          []
        );

        setSummaries(
          tempSummaries.length > 0
            ? tempSummaries
            : [
                {
                  id: "0",
                  summary: "You don't have any conversation summary yet",
                  time: "",
                },
              ]
        );

        // Update cache
        localStorage.setItem("cachedSummaries", JSON.stringify(tempSummaries));
      }
    } catch (error) {
      Logger.error("Error fetching summary: ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Header />

      <div className="profile-background">
        <div className="wait-list-text">
          {isOnWaitlist ? (
            <p>
              Thank you for signing up! You have been added to our waitlist.
              Please anticipate an email notification from us.
            </p>
          ) : null}
        </div>

        {!isOnWaitlist && (
          <div>
            <div className="summary-btn-container">
              <button className="summary-btn" onClick={getSummary}>
                Get Summaries
              </button>
            </div>

            <div>
              {/* <CreditsTab credits={userCredits} userPlan={userPlan} /> */}
              <CreditsTab credits={userCredits} />
            </div>

            <div className="summary-form-container">
              {loading ? (
                <p>Loading summaries...</p>
              ) : summaries.length === 0 ? (
                <p>No summaries found.</p>
              ) : (
                summaries.map((summary) => (
                  <div key={summary.id} className="summary-container">
                    <div
                      onClick={() => toggleSummary(summary.id)}
                      className="summary-header"
                    >
                      <div className="summary-time">
                        {formatDate(summary.time)} {/* Display the date as clickable */}
                      </div>
                    </div>
                    {expandedSummaryId === summary.id && ( // Conditionally render the summary details
                      <div>
                        <p className="summary-text">{summary.summary}</p>
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>
          </div>
        )}
      </div>

      {/* <div className="footer-container"> */}
      <FrontPageFooter />
      {/* </div> */}
    </div>
  );
};
export default ProfilePage;
